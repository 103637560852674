

//import { Link }
//from "gatsby"



import React from "react"
import i18next from '../i18n/config';
// import ImgHeader from "../img/A_glowna_1/tlo_glowna.jpg";
import EmployeeIcon from "../img/S_dla_pracownika_11/iko_pracownik.png";
import EmployeeIcon2 from "../img/S_dla_pracownika_11/iko_chmura.png";
import EmployeeIcon3 from "../img/icon/iko_video.png";
import {Link, Trans, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';


export default function Header(props) {



    return (
            <React.Fragment>

                <section class="employee firstSection">
                    <h1>{i18next.t('EmployeeZoneH1')}</h1>
                    <p>{i18next.t('EmployeeZoneP')}</p>
                    <div class="Eicons">
                        <Link
                            to="/ForEmployees/EPracownik/"
                            className={('nav__link')}
                            activeStyle={{color: "#fff"}}
                            partiallyActive={true}
                            >
                        <div class="Eicon">
                            <div><img loading="lazy" src={EmployeeIcon} alt="Instanta Epracownik" /></div>
                            <p>{i18next.t('EmployeeZoneLink1')}</p>
                        </div>

                        </Link>



                        <Link
                            to="/ForEmployees/Cloud/"
                            className={('nav__link')}
                            activeStyle={{color: "#fff"}}
                            partiallyActive={true}
                            >


                        <div class="Eicon">
                            <div><img loading="lazy" src={EmployeeIcon2} alt="Instanta Cloud" /></div>
                            <p>{i18next.t('EmployeeZoneLink2')}</p>
                        </div>
                        </Link>

                    </div>


                </section>



            </React.Fragment>

            )


}
